<template>
  <div
    class="flex m-auto w-4/5 md:w-5/6 2xl:w-9/12 max-w-full h-full rounded-xl"
    style="background: #110e1f; min-height: 60vh"
  >
    <div class="xl:grid gap-4 w-full" style="grid-template-columns: 1.5fr 2fr">
      <div
        class="hidden xl:block object-cover p-6 w-full h-full bg-center bg-no-repeat bg-cover rounded-l-xl"
        :class="`tab-bg--${tab}`"
      >
        <Icon icon="netwrk" width="100" color="#fff" class="opacity-70" />

        <div v-if="tab === 1" class="flex flex-col gap-4 justify-center h-full">
          <!-- <Title title="Thank you for Signing up!" color="#fff" size="small" class="opacity-70" /> -->

          <h1 class="text-3xl xl:text-5xl font-bold leading-snug">
            Tell us about <span class="text-signal-green"> yourself. </span>
          </h1>
        </div>

        <div v-else-if="tab === 2" class="flex flex-col gap-4 justify-center h-full">
          <h1 class="text-3xl xl:text-5xl font-bold leading-snug">
            What type of <span class="text-signal-green"> content </span> do you create?
          </h1>
        </div>

        <div v-else-if="tab === 3" class="flex flex-col gap-4 justify-center h-full">
          <h1 class="text-3xl xl:text-5xl font-bold leading-snug">
            <span class="text-signal-green">We'll help</span> you through your challenges.
          </h1>
        </div>
      </div>

      <div class="flex flex-col gap-8 p-2 md:p-4 xl:p-8 mx-auto w-full h-full" style="max-width: 80%">
        <div class="grid grid-cols-3">
          <div
            v-for="num in 3"
            :key="num"
            style="width: 50px; height: 50px"
            class="flex place-content-center place-items-center m-auto rounded-full"
            :class="tab >= num ? 'bg-signal-green' : 'bg-blue-light'"
          >
            <Icon v-if="tab > num" icon="check" width="16px" height="16px" />

            <p v-else>
              {{ num }}
            </p>
          </div>
        </div>

        <div v-if="tab === 1" class="mx-auto">
          <Title title="Let us know more about you." class="mb-8 font-medium text-center" size="medium" />

          <AutoComplete
            :data="countriesList"
            label="Which  country are you from?"
            class="mb-8 rounded"
            :value="streamerInfo && streamerInfo.info ? streamerInfo.info.country : ''"
            @selectedItem="setCountry"
          />

          <AutoComplete
            :data="languageList"
            :value="streamerInfo && streamerInfo.info ? streamerInfo.info.broadcastingLanguage : ''"
            label="What language do you stream in?"
            @selectedItem="setBroadcastingLanguage"
          />
        </div>

        <div v-else-if="tab === 2" class="mx-auto">
          <Title
            title="Choose one, or more, that best represents your content."
            class="mb-8 font-medium text-center"
            size="medium"
          />

          <div class="grid grid-cols-2 gap-x-6 gap-y-3">
            <CheckboxText
              v-for="(tag, tagIndex) of userTags"
              :key="`tagIndex ${tagIndex}`"
              :text="tag.name"
              :checked="tag.checked"
              :icon="tag.icon"
              @checked="switchTagState($event, tagIndex)"
            />
          </div>
        </div>

        <div v-else-if="tab === 3" class="mx-auto">
          <Title
            title="What is the primary challenge you face in achieving your goals?"
            class="mb-8 font-medium text-center"
            size="medium"
          />

          <div class="flex flex-col gap-4">
            <CheckboxText
              v-for="(goal, goalIndex) of streamerGoals"
              :key="`goalIndex ${goalIndex}`"
              :text="goal.text"
              :checked="goal.checked"
              @checked="switchGoalState($event, goalIndex)"
            />
          </div>
        </div>

        <div class="flex mt-auto w-full">
          <Button v-if="tab > 1" label="Back" kind="secondary" @clicked="switchPage(-1)" />
          <Button :label="tab === 3 ? 'Finish' : 'Next'" kind="primary" class="ml-auto" @clicked="switchPage(1)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { Icon, Title, Button, CheckboxText } from "cavea-design-system";
import countryCode from "@/lib/helpers/countryCodes";
import log from "@/lib/log";

export default {
  name: "StreamerSignup",

  metaInfo: {
    title: "Signup Flow",
  },

  components: {
    Icon,
    Title,
    Button,
    CheckboxText,
    AutoComplete: () => import("@/components/inputs/AutoComplete"),
  },

  data() {
    return {
      tab: 1,
      acceptedTerms: false,

      streamerInfo: null,

      languageList: [],

      userTags: [],

      streamerGoals: [],
    };
  },

  computed: {
    ...mapGetters(["getUserInfo"]),

    countriesList() {
      return countryCode("all").map((c) => c.country);
    },
  },

  created() {
    this.streamerInfo = this.getUserInfo;

    this.fetchLanguageList();
    this.fetchTags();
    this.fetchGoals();
  },

  methods: {
    ...mapActions(["initStore", "setUserInfo"]),

    async fetchLanguageList() {
      log("fetchLanguageList");

      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/get-languages`;

      this.languageList = await axios
        .get(URL)
        .then((res) => res?.data?.languages)
        .catch((err) => {
          console.error("error getting languages", err);
          return [];
        });
    },

    async fetchTags() {
      log("fetchTags");

      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/tags`;

      const tags = await axios
        .get(URL)
        .then((res) => res?.data?.tags)
        .catch((error) => {
          console.error("Error fetching tags", error);
        });

      if (tags?.length) {
        this.userTags = tags.map((t) => ({ ...t, checked: false }));
      } else {
        this.fetchTags();
      }
    },

    async fetchGoals() {
      log("fetchGoals");

      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/goals`;

      const goals = await axios
        .get(URL)
        .then((res) => res?.data?.goals)
        .catch((error) => {
          console.error("Error fetching tags", error);
        });

      if (goals?.length) {
        this.streamerGoals = goals.map((g) => ({ ...g, checked: false }));
      } else {
        this.fetchGoals();
      }
    },

    /**
     * @param { number } direction -1 | 1
     */
    async switchPage(direction) {
      log("switchPage");

      if (direction === 1 && this.tab === 3) {
        log("switchPage FINISH");

        this.streamerInfo.tags = this.userTags?.filter((t) => t?.checked === true)?.map((t) => t?._id) || [];
        this.streamerInfo.goals = this.streamerGoals?.filter((g) => g?.checked === true)?.map((g) => g?._id) || [];

        log({
          fn: "switchPage",
          tags: this.streamerInfo.tags,
        });

        this.updateUserSettings();

        this.$router.push("/dashboard");
      } else if (direction === 1) {
        this.tab += 1;
      } else if (direction === -1) {
        this.tab -= 1;
      }
    },

    /**
     * @param {string} value
     */
    setCountry(value) {
      log(`setCountry ${value}`);

      this.streamerInfo.info.country = value?.toLowerCase();
    },

    /**
     * @param {string} value
     */
    setBroadcastingLanguage(value) {
      log(`setBroadcastingLanguage ${value}`);

      this.streamerInfo.info.broadcastingLanguage = value?.toLowerCase();
    },

    /**
     * @param {{ checkedState: boolean }} event
     * @param { number } index
     */
    switchTagState(event, index) {
      log("switchTagState");

      this.userTags[index].checked = event?.checkedState;
    },

    /**
     * @param {{ checkedState: boolean }} event
     * @param { number } index
     */
    switchGoalState(event, index) {
      log("switchGoalState");

      this.streamerGoals[index].checked = event?.checkedState;
    },

    async updateUserSettings() {
      log("updateUserSettings");

      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/content-creator/settings`;

      await axios.post(URL, this.streamerInfo);

      this.initStore();
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-bg {
  &--0 {
    background: radial-gradient(
        75.31% 78.45% at -44.01% 132.06%,
        rgba(65, 209, 107, 0.31) 0%,
        rgba(65, 209, 107, 0) 100%
      ),
      linear-gradient(46.89deg, #120f20 16.02%, rgba(35, 30, 61, 0.62) 85.07%),
      url("/close-up-streamer-woman-talking-into-professional-microphone-home-studio-online-streaming-cyber-performing-gaming-tournament-using-technology-network-wireless.jpg");
  }

  &--1 {
    background: radial-gradient(
        133.94% 68.5% at -39.77% -44.21%,
        rgba(65, 209, 107, 0.31) 0%,
        rgba(65, 209, 107, 0) 100%
      ),
      linear-gradient(156.23deg, #231e3d 5.26%, rgba(35, 30, 61, 0.62) 94.87%),
      url("/blurry-female-vlogger-online-streaming.jpg");
  }

  &--2 {
    background: radial-gradient(
        133.94% 68.5% at -39.77% -44.21%,
        rgba(65, 209, 107, 0.31) 0%,
        rgba(65, 209, 107, 0) 100%
      ),
      linear-gradient(156.23deg, #231e3d 5.26%, rgba(35, 30, 61, 0.62) 94.87%),
      url("/man-playing-shooter-games-with-other-players-late-night-streaming-esports-competition.jpg");
  }
  &--3 {
    background: radial-gradient(
        133.94% 68.5% at -39.77% -44.21%,
        rgba(65, 209, 107, 0.31) 0%,
        rgba(65, 209, 107, 0) 100%
      ),
      linear-gradient(156.23deg, #231e3d 5.26%, rgba(35, 30, 61, 0.62) 94.87%),
      url("/videogamer-player-raising-hands-after-winning-first-person-shooter-competition-wearing-hradphones-professional-pro-gamer-playing-online-video-games-with-new-graphics-powerful-computer.jpg");
  }
}
</style>
